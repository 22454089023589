<template>
  <div class="examination1">
    <div class="langscap" :style="'width:' +  screenHeight + 'px; height:' + screenHeight + 'px;'">
      <div class="warp" :style="'width:' + screenHeight  + 'px; height:' + screenWidth + 'px;'">
        <div class="titleBgimg">
          <img src="@/assets/image/goback.png" @click="goback">
          <span class="titleTop">驾驶人科目{{ car == 1 ? '一' : '四' }}考试系统</span>
        </div>
        <div class="main">
          <div class="mainAnnotation">
            <div class="mainLeft">
              <p class="notice">རྒྱུགས་ལེན་ངེས་ཤེས།:</p>
              <p class="text">1、རྒྱུགས་རའི་སྒྲིག་ལམ་བརྩི་སྲུང་དང་། རྒྱུགས་སྲུང་མི་སྣའི་བཀོད་འདོམས་ལ་ཉན་དགོས།</p>
              <p class="text">2、རྒྱུགས་རར་འཛུལ་རྫེས། ཁ་པར་སྒོ་རྒྱག་པ་དང་། ཐ་མག་འཐེན་མི་ཆོག་པ། ཞོར་ཟས་ཟ་མི་ཆོག་པ།</p>
              <p class="text">3、ལས་དོན་མི་སྣའི་ཆོག་མཆན་མ་ཐོབ་པར་རྒྱུགས་སྤྲོད་སློབ་མ་གང་འདོད་དུ་འགྲོ་འོང་བྱ་མི་ཆོག</p>
              <p class="text">4、རྒྱུགས་རའི་ནང་དུ་འུར་རྒྱག་མི་ཆོག་པ་དང་།</p>
              <p class="text">5、དྲི་བ་ལན་བཏབ་ཚར་རྫེས་སྐར་མ་20རྫེས་ནས་ད་གཟོད་རྒྱུགས་སྤྲོད་ཆོག</p>
            </div>
            <div class="shu"></div>
            <div class="mainRight">
              <p class="theory">理论考试01号考台 <span> 合格次数:**</span></p>
              <p class="idCard">身份证号: <span>1234********789</span></p>
              <p class="idCard">考生姓名: <span>{{user.nickname}}</span></p>
              <div class="queryThat" @click="queryThat">确定</div>
              <p class="idCard fontSize">点击"确定"按钮开始考试!	</p>
              <p class="explan">
                操作提示:每题考试答案确定后，点击【下一题]，电脑立即判定所选答案，如选择错误，系统将提示正确答案，提示后不允许修改答案。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { vip, getUser } from '@/api'
import { countOne, countFour } from "@/api/count";
import { Dialog } from 'vant'
export default {
  data() {
    return {
      screenWidth: '',
      screenHeight: '',
      car: 1,
      user: {}
    }
  },
  created() {
    this.getUserIfo()
    this.screenHeight = window.innerHeight
    this.screenWidth = window.innerWidth
    this.car = this.$route.query.id
  },
  methods: {
    getUserIfo() {
      getUser().then(res => {
        this.user = res.data
      })
    },
    goback() {
      this.$router.push('/')
    },
    // 获取科一考试题目
    getcount() {
      countOne().then((res) => {
        // this.countList = res.data;
        // this.countForm = this.countList[0];
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          if (res.data.length === 0) {
            Dialog.alert({
              title: '提示',
              message: '暂无题目,正在升级敬请期待~'
            })
          return
          } else {
            localStorage.setItem('tixing', JSON.stringify(res.data))
            this.$router.push({
              path: '/realExamination',
              query: {
                id: this.car
              }
            })
          }
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 获取科四考试题目
    getfour() {
      countFour().then((res) => {
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          localStorage.setItem('tixing', JSON.stringify(res.data))
          this.$router.push({
            path: '/realExamination',
            query: {
              id: this.car
            }
          })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 去考试
    queryThat() {
      vip().then(res => {
        localStorage.setItem('vip', res.data)
      })
      if (this.car == 1) {
        this.getcount();
      } else {
        this.getfour();
      }
    }
  }
}
</script>

<style scoped lang="scss">

.examination1{
  width: 100%;
  height: 100%;
  overflow: hidden;
  .langscap{
    transform: rotate(90deg);
    transform-origin: bottom top;
    background: #F1F4EE;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: flex-end;
  }
  .warp{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .titleBgimg{
      width: 630px;
      height: 81px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-image: url('../assets/image/titleBgimg.png');
      background-size: cover;
      img{
        width: 17px;
        height: 15px;
        margin-left: 20px;
      }
      .titleTop{
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 100;
        font-size: 25px;
        color: #049DFE;
        line-height: 0px;
        text-shadow: 0px 2px 2px rgba(0,0,0,0.16);
        text-stroke: 1px #FFFFFF;
        text-align: left;
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 1px #FFFFFF;
        margin-left: 143px;
      }
    }
    .main{
      width: 629px;
      height: 293px;
      background: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      .mainAnnotation{
        width: 577px;
        height: 260px;
        background: #F1F4ED;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #B5B5B5;
        display: flex;
        justify-content: center;
        align-items: center;
        .mainLeft{
          width: 300px;
          height: 260px;
          padding-left: 10px;
          background: #F1F4ED;
          border-right: 1px solid #B5B5B5;
          .notice{
            padding-top: 14px;
            margin-bottom: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 16px;
            color: #333333;
          }
          .text{
            font-family: PingFang SC, PingFang SC;
            font-weight: bold;
            font-size: 12px;
            margin-bottom: 12px;
            color: #333333;
          }
        }
        .mainRight{
          width: 250px;
          height: 245px;
          padding-left: 10px;
          padding-top: 15px;
          border-left: 1px solid #B5B5B5;
          .theory{
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 14px;
            color: #333333;
            span{
              padding-left: 10px;
            }
          }
          .idCard{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 12px;
            color: #FF4A40;
            margin-top: 10px;
            span{
              padding-left: 4px;
            }
          }
          .fontSize{
            font-size: 12px;
            margin-top: 20px;
          }
          .queryThat{
            width: 79px;
            height: 31px;
            margin-top: 16px;
            margin-left: 53px;
            background: #FFFFFF;
            border-radius: 0px 0px 0px 0px;
            border: 1px solid #949494;
            text-align: center;
            line-height: 31px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 800;
            font-size: 16px;
            color: #000000;
          }
          .explan{
            margin-top: 12px;
            font-family: PingFang SC, PingFang SC;
            font-size: 12px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
